<div>
  <!-- Modal Expired Quote -->
  <div class="modal show" [ngClass]="{ mobileBg: isMobile }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <span>
            This quote is no longer valid due to our transition to a new
            platform. Please arrange a new quote by visiting
            <a href="https://www.racq.com.au/travelinsurance"
              >racq.com/travelinsurance</a
            >.
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
