export const environment = {
  production: true,
  envName: 'prod',
  autoClub: 'RACQ',
  veriskNetworkUsername: '',
  veriskNetworkPassword: '',
  addressify: {
    baseUrl: 'https://api.addressify.com.au',
    apiKey: '0E4644FE-85E4-4685-930F-DBF5F8629DFA',
  },
  baseAPIUrl: 'https://apim-aue-ltx-api-dev.azure-api.net/v1/api/',
  verisk: {
    username: 'BB3TokioMarineRACQAusTravelUAT',
    vrrInternal:
      'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVG9raW8gTWFyaW5lIFJBQ1EgQXVzIFRyYXZlbCBCQjMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJCbGFja0JveEN1c3RvbWVyIiwiY2xpZW50SWQiOiI5MCIsImlzcyI6IkJCLk1haW50ZW5hbmNlVG9vbC5UZXN0IiwiYXVkIjoiVlJSLkJCMy5VQVQifQ.0S5yWsdgTsxWWkJlhOrxU5Kh_adoA6WOvwuOxgAOcjE',
  },
  DMSAPI: {
    baseUrl: 'https://dmsapiservices.azurewebsites.net/',
    apiKey: 'A3886FC2E95C962113161EBBC6F6E',
  },
  PASUrl: 'https://pasdev.tmaainsurance.com',
};
